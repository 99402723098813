/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Fullmap, FullmapWrap, Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, Button, SeparateLine } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Chci pronajmout"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Fullmap className="--style2" name={"e9jd34g191i"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="--center pb--80 pt--80" style={{"backgroundColor":"#f5f8fd"}} name={"ocpl270ihkd"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Chci pronajmout\n\n\n\n"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":1070,"marginTop":22}} content={"Pokud hledáte spolehlivého nájemníka pro vaši nemovitost, jsem zde, abych vám pomohl. Zajistím profesionální prezentaci vaší nemovitosti a nalezení důvěryhodného nájemníka, který bude vaši nemovitost respektovat. S mým přístupem získáte klid a jistotu v pronájmu.\n\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"3l2zi8a56dj"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--bottom" style={{"paddingLeft":0}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":596}}>
              
              <Title className="title-box fs--30" content={"Co je důležité při pronájmu ? \n\n \n\n\n"}>
              </Title>

              <Text className="text-box fs--16" style={{"marginTop":35}} content={"Vzhledem k mé praxi v realitním sektoru jsem získal cenné zkušenosti, které mi umožňují s vysokou mírou pravděpodobnosti identifikovat potenciální rizika spojená s pronájmem. Rozumím tomu, že výběr správného nájemníka je klíčový, a i když stoprocentní záruku nikdy dát nemůžeme, mám osvědčené metody, jak minimalizovat potenciální problémy.\n\n\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"marginTop":35}} content={"K důkladnému prověření potenciálních nájemníků využívám nejen osobní zkušenosti, ale i dostupné veřejné rejstříky, včetně exekucí a insolvencí. Tento proces mi umožňuje lépe pochopit finanční a osobní pozadí zájemců o pronájem a vybrat toho nejvhodnějšího kandidáta, který bude respektovat vaši nemovitost jako svůj domov.  \n\n  \n\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"marginTop":35}} content={"Pokud hledáte způsob, jak svou nemovitost pronajmout s minimálními starostmi a zároveň si zajistit pravidelný výnos, jsem tu pro vás. Díky mému profesionálnímu přístupu a detailnímu prověření nájemníků se můžete spolehnout, že vaše nemovitost bude v dobrých rukou. Nechte na mě starosti s výběrem nájemníka a administrativou spojenou s pronájmem, a užívejte si klid a jistotu pravidelného příjmu.\n\n\n\n\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <ContactForm className="--shape3" action={"contact"} name={"9velcwwvqg"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno","id":"ndsbszx345p"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte svůj e-mail","id":"hx6mo2bteq"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420","id":"mbhc4li3ha"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy","id":"uudb3rmixl8"},{"name":"Odeslat","type":"submit","id":"chakkgeyurq"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1c4hlub css-c2cxkz --parallax pb--60 pt--60" name={"prefooter"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=3000x_.png);
    }
  
background-position: 50% 25%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--shadow4 fs--48 w--600 lh--14 pb--06 pt--06" style={{"maxWidth":824}} content={"<span style=\"color: var(--color-custom-2);\">Stavíme mosty k vašim vysněným nemovitostem\n</span>"}>
              </Title>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape4" style={{"marginTop":0}} content={"Kontaktovat"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"k57myfoevo"}>
        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box w--500" style={{"maxWidth":""}} content={"Häusl&amp;Home Reality"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={" Ke kapslovně 2855/1 Praha 3 130 00\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"tel:+420 724 808 718\" style=\"color: var(--color-blend--05);\">+420 724 808 718\n</a>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"mailto:miroslav.hausl@hauslahome.cz\" style=\"color: var(--color-blend--05);\">miroslav.hausl@hauslahome.cz\n</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right" content={"<a href=\"/sluzby\">Služby</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/nemovistinaprodej\">Nemovitosti</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/kontakt\">Kontakt</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}